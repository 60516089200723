/* eslint-disable max-statements */
import { Common } from '@icc/common/Common';
import { core } from '@icc/common/helpers';
import { ConfiguratorsDataService } from '@icc/common/configurators/configurators-data.service';
import { CurrentConfiguratorService } from '@icc/common/configurators/current-configurator.service';
import { ParametersService } from '@icc/common/configurators/parameters.service';
import { ConfigurationsService, AppConfigFactory, APP_CONFIG, EventBusService, HandleHeightService, TranslateService, UserService, WindowActiveConfiguration, SizeRangeService } from '@icc/common';
import { PriceService } from '@icc/price';
import { IssuesService, ModalService, unitMMFormatter, IssueLevel } from '@icc/helpers';
import { LocksService } from '../../door/locks/locks.service';
import { Inject, Injectable } from '@angular/core';
import { ColorMappingService } from '@icc/common/colors/colors-mapping.service';
import { TimeLimitService } from '@icc/common/time-limit/time-limit.service';
import { ConstructionLimitationService } from '../dimensions/construction-limitation.service';
import { ActiveSash } from '@icc/common/layout/active-sash';
import { HandlesPageComponent } from 'libs/configurator/window/src/lib/fittings/handles-page/handles-page.component';
import { HingesPageComponent } from 'libs/configurator/window/src/lib/fittings/hinges-page/hinges-page.component';
import { IccSashType, IccAccessoryColor } from '@icc/common/data-types';
import { DoorActiveConfiguration } from '@icc/common/configurations/DoorActiveConfiguration';
import { HandlesOptionsPageComponent } from 'libs/configurator/window/src/lib/handles-options-page/handles-options-page.component';
import { HingesOptionsPageComponent } from 'libs/configurator/window/src/lib/hinges-options-page/hinges-options-page.component';
import { HandleHeight, HandlesType, HandlesPlace} from '@icc/window';
import { WindowFittingRelation } from '@icc/common/data-types/Handle';

@Injectable()
export class HandlesService {

    private configurators = ['window', 'hs', 'door', 'folding_door', 'sliding_door'];
    private noHandleTypes = ['F', 'FF', 'OFF', 'DS', 'DSC', 'DRP', 'ODS', 'DOP'];
    private allFittings = [];
    private allHandles = [];
    private allHinges = [];
    private isDoorVersion2: any;
    private showWindowLeverBox: any;
    private handleCategories = [];
    private handleSubcategories = [];
    private handleColorsAll: IccAccessoryColor[] = [];
    private sashTypes: string[] = [];
    private modalHandleData = {
        selectedCategory: undefined,
        selectedSubcategory: undefined,
    };

    fittings: any[] = [];
    handles: any[] = [];
    handlesForSelect = [];
    hinges: any = [];
    handleColors: IccAccessoryColor[] = [];
    tags = [];
    loadedData = false;
    isMatch = { ref: false };
    systemHeight = this.handleHeightService.systemHeight;
    private dependencies = [];
    constructor (
        private translateService: TranslateService,
        private modalService: ModalService,
        private configuratorsDataService: ConfiguratorsDataService,
        private currentConfiguratorService: CurrentConfiguratorService, // eslint-disable-line max-params
        private parametersService: ParametersService,
        private configurationsService: ConfigurationsService<'window' | 'door'>,
        private priceService: PriceService,
        private issuesService: IssuesService,
        private locksService: LocksService,
        @Inject(APP_CONFIG) private config: AppConfigFactory,
        private colorMappingService: ColorMappingService,
        private eventBusService: EventBusService,
        private handleHeightService: HandleHeightService,
        private timeLimitService: TimeLimitService,
        private constructionLimitationService: ConstructionLimitationService,
        private userService: UserService,
        private sizeRangeService: SizeRangeService
    ) {
        if (this.configuratorsDataService.loaded) {
            this.init();
        }

        this.eventBusService.subscribeWithoutConfiguration(
            ['initializedConfigurator', 'loadedConfiguratorsData'],
            () => {
                this.init();
            }
        );

        this.eventBusService.subscribeWithoutConfiguration('setSystem', data => {
                this.findFittingsBySystem();
                this.findFittingBySizeRanges();
                this.findHandlesBySystem();
                this.checkHandleType(data.activeConfiguration as WindowActiveConfiguration);
                this.validateHandleAndFixIssues();
                this.findHingesBySystem();
                this.setDefaultHingeForSystem(data.activeConfiguration as WindowActiveConfiguration);
            }
        );

        this.eventBusService.subscribe(['changedSashes', 'changedFrames'], data => {
            this.findFittingsBySystem();
            this.findFittingBySizeRanges();
            this.checkHandleType(data.activeConfiguration as WindowActiveConfiguration);
            this.validateHandleAndFixIssues();

        });

        this.eventBusService.subscribe('setConstructionColor', data => {
            if (WindowActiveConfiguration.is(data.activeConfiguration)) {
                this.validateHandleAndFixIssues();
                this.setDefaultColorForHinge();
            }
        })

    }

    /**
     * Funkcja inicjalizacyjna
     */
    init() {
        this.dependencies = this.configuratorsDataService.data.dependencies || [];
        this.isDoorVersion2 = this.config().IccConfig.Configurators.door.version === 2 && this.configurationsService.conf.Current.type === 'door';
        this.showWindowLeverBox= this.configurationsService.conf.Current.OwnedSashesTypes?.window && this.configurationsService.conf.Current.Sashes?.some(el => !['F', 'FF', 'OFF', 'DRA', 'DRP', 'DOA', 'DOP'].includes(el.type.type))
                        && this.configurationsService.conf.Current.HasHandle && this.isDoorVersion2;
        this.handleCategories = this.configuratorsDataService.data.handleCategory;
        this.handleSubcategories = this.configuratorsDataService.data.handleSubcategory;
        this.allFittings = this.configuratorsDataService.data.windowFittings;
        this.allHandles = core.copy(this.configuratorsDataService.data.windowHandlers);
        this.allHinges = core.copy(this.configuratorsDataService.data.hinges);
        this.handleColorsAll = this.configuratorsDataService.data.windowHandlesColors;
        this.fittings = this.configuratorsDataService.data.windowFittings;
        this.handleColors = this.configuratorsDataService.data.windowHandlesColors;
        this.tags = [];
        if (Common.isArray(this.configuratorsDataService.data.systemsTags)) {
            this.tags = this.configuratorsDataService.data.systemsTags.filter(
                tag => tag.type === 'window_fitting'
            );
        }
        if (this.configurators.indexOf(this.currentConfiguratorService.conf) === -1) {
            return;
        }

        if (this.configurators.indexOf(this.currentConfiguratorService.conf) !== -1) {
            this.findFittingsBySystem();
            this.findFittingBySizeRanges();
            this.findHandlesBySystem();
            this.findHingesBySystem();
            this.setDefaultHingeForSystem();

            if (
                Common.isUndefined(this.configurationsService.conf.addPositionToGroup)
                || !this.configurationsService.conf.addPositionToGroup
            ) {
               this.validateHandleAndFixIssues();
            }

            this.refreshTypes(this.configurationsService.conf.Current);
        }

        this.loadedData = true;
    }

    /**
     * Funkcja ustawiajaca domyślne okucie bezpieczne
     */
    setDefaultFitting(
        conf = this.configurationsService.conf.Current,
        defaultConf = this.configurationsService.conf.Default
    ) {
        if (Common.isUndefined(conf)) {
            return;
        }
        if (Common.isArray(this.fittings) && this.fittings.length > 0) {
            if (
                Common.isObject(defaultConf.Fitting)
                && Common.isDefined(defaultConf.Fitting.id)
                && this.fittings.filter(
                    f => f.id == defaultConf.Fitting.id
                ).length > 0
            ) {
                if(this.config().IccConfig.Configurators.sizeRangesFitting) {
                    if(Number(conf.Fitting.id) !== Number(defaultConf.Fitting.id)) {
                        conf.Fitting = defaultConf.Fitting;
                    }
                } else {
                    conf.Fitting = defaultConf.Fitting;
                }
            } else {
                conf.Fitting = this.fittings[0];
                defaultConf.Fitting = this.fittings[0];
            }
        }
        this.findHingesBySystem();
        this.setDefaultHingeForSystem();
        this.eventBusService.post({
            key: 'changedFitting',
            value: null,
            conf,
            defaultConf
        });
        this.priceService.count();
        this.parametersService.count(conf);
    }

    /**
     * Funkcja wybierajaca okucie
     * @param  {object} fitting Okucie
     */
    selectFitting(fitting) {
        this.configurationsService.conf.Current.Fitting = fitting;
        this.configurationsService.conf.Default.Fitting = fitting;

        if (fitting.steel_closed && this.configurationsService.conf.Current.Steel != 'Closed') {
            this.configurationsService.conf.Current.Steel = 'Closed';
        }

        this.findHingesBySystem();
        this.setDefaultHingeForSystem();
        this.findHandlesBySystem();
        if (
            this.config().IccConfig.Configurators.filteringHandlesForRC
            || this.config().IccConfig.Configurators.selectHandleOnChangeFitting
        ) {
            this.setHandleType(this.configurationsService.conf.Current.HandleType);
        }
        this.refreshTypes(this.configurationsService.conf.Current);
        this.valid(this.configurationsService.conf.Current);
        this.eventBusService.post({
            key: 'changedFitting',
            value: null,
        });
        this.parametersService.count(this.configurationsService.conf.Current);
        if (this.config().IccConfig.Configurators.dependencies) {
            this.eventBusService.post({ key: 'processDependencies', value: null });
        }
        this.priceService.count();
    }

    getSashesHandleFilter() {
        return sash => {
            const noHandle = !sash.handle || !sash.handle.id;
            const fittingNotAvailable = !this.config().IccConfig.Configurators.sizeRangesFitting
                && sash.handle.window_fittings_ids
                && sash.handle.window_fittings_ids.indexOf(this.configurationsService.conf.Current.Fitting.id) === -1 || true
            const fittingNotAvailableForSizeRange = this.config().IccConfig.Configurators.sizeRangesFitting
                && sash.handle?.windowFittingsWithRelationType
                && sash.handle?.windowFittingsWithRelationType.find((f: WindowFittingRelation)  =>
                    Number(f.windowFittingId) === Number(this.configurationsService.conf.Current.Fitting.id)
                )?.relationType === 'blockade' || true;
            const fittingNotAvailableForSizeRangeOuter = this.config().IccConfig.Configurators.sizeRangesFitting
                && sash.handleOuter?.windowFittingsWithRelationType
                && sash.handleOuter?.windowFittingsWithRelationType.find((f: WindowFittingRelation) =>
                    Number(f.windowFittingId) === Number(this.configurationsService.conf.Current.Fitting.id)
                )?.relationType === 'blockade';

            return noHandle || fittingNotAvailable || fittingNotAvailableForSizeRange || fittingNotAvailableForSizeRangeOuter;
        }
    }

    /**
     * Funkcja znajdujaca okucie wh systemu
     * @param  {object} system System
     * @return {object}        Okucia
     */
    findFittingsBySystem(
        system?,
        conf = this.configurationsService.conf.Current,
        defaultConf = this.configurationsService.conf.Default
    ) {
        if (Common.isUndefined(conf) && !(system)) {
            return [];
        }

        let systemPassed = true;
        if (!(system)) {
            system = conf.System;
            systemPassed = false;
        }

        this.fittings = [];
        Common.forEach(this.allFittings, fitting => {
            const systemTypes = fitting.system_types;
            const systemIds = fitting.window_lines_ids;
            if (Common.isArray(systemTypes)) {
                if (Common.isObject(system)) {
                    if (
                        systemTypes.indexOf(system.type) > -1
                        && systemIds.indexOf(system.id) > -1
                    ) {
                        this.fittings.push(fitting);
                    }
                } else {
                    if (systemTypes.indexOf('pvc') > -1) {
                        this.fittings.push(fitting);
                    }
                }
            }
        });

        if (!systemPassed) {
            this.setDefaultFitting(conf, defaultConf);
            this.eventBusService.post({
                key: 'refreshHandlesColors',
                value: null
            });
            return this.fittings;
        } else {
            return this.fittings;
        }
    }

    findFittingBySizeRanges(
        conf = this.configurationsService.conf.Current,
        defaultConf = this.configurationsService.conf.Default
    ) {
        if(this.config().IccConfig.Configurators.sizeRangesFitting) {
            this.fittings = this.fittings.filter(fitting => {
                if(!fitting.size_range_id) {
                    return true;
                }
                let isValidSizeRange = true
                conf.Sashes.forEach(sash => {
                    if(sash.type.handle_position) {
                        const sashDimensions = conf.drawData.sashFrame.find(draw => Number(draw.sashId) === sash.id);
                        if(sashDimensions) {
                            const profile = conf.UsedProfiles.find(p => Number(p.id) === Number(sash.frame.left.profileId));
                            if (!profile) {
                                isValidSizeRange = false;
                                return;
                            }
                            const width = sashDimensions.outer.rect.width - 2 * profile.rebateWidth;
                            const height = sashDimensions.outer.rect.height - 2 * profile.rebateWidth;
                            const sizeRanges = this.sizeRangeService.getSizeRange(Number(fitting.size_range_id), width, height, false);
                            if(!(sizeRanges && sizeRanges?.isValid)) {
                                isValidSizeRange = false
                            }
                        }
                    }
                });
                if (isValidSizeRange) {
                   return true;
                }
                return false;
            });
            this.setDefaultFitting(conf, defaultConf);
            this.findHandlesBySystem();
            if (
                this.config().IccConfig.Configurators.filteringHandlesForRC
                || this.config().IccConfig.Configurators.selectHandleOnChangeFitting
            ) {
                const handleType = this.configurationsService.conf.Default.HandleType || this.configurationsService.conf.Current.HandleType
                this.setHandleType(handleType);
            }

        }
    }

    /**
     * Funkcja ustawiajaca klamke
     * @param {object} sash      Skrzydło
     * @param {string} place     Miejsce
     * @param {object} newHandle Nowa klamka
     * @param {object} check     Sprawdzanie
     * @param {object} noRedraw  noRedraw
     */
    setHandle(
        sash,
        place,
        newHandle,
        check = false,
        noRedraw = false,
        validate = true,
        conf = this.configurationsService.conf.Current,
        defaultConf = this.configurationsService.conf.Default,
    ) {
        if (
            !sash
            || !conf.System
            || !conf.System.id
        ) {
            return;
        }
        if (this.noHandleTypes.indexOf(sash.type.type) == -1) {
            if (
                !Common.isObject(newHandle)
                || (newHandle.sash_types_ids || []).indexOf(sash.type.id) === -1
            ) {
                const handlePlace = place === 'outer' ? 'handleOuter' : 'handle';
                newHandle = this.getDefaultHandleIdForSash(conf, sash, handlePlace);
            }
            if (Common.isUndefined(newHandle)) {
                this.issuesService.registerDataProblem(
                    'no-handle-available',
                    'Brak podpiętej klamki do systemu',
                    conf,
                    {
                        extra: {
                            systemId: conf.System.id,
                            systemName: conf.System.name,
                        },
                    }
                );
                return;
            } else {
                this.issuesService.unregister(
                    'no-handle-available',
                    conf
                );
            }
            if (newHandle.sash_types_ids.indexOf(sash.type.id) === -1) {
                const matchedHandles = this.getHandleForSashType(sash.type.id);
                if (Common.isArray(matchedHandles) && matchedHandles.length > 0) {
                    newHandle = matchedHandles[0];
                    this.issuesService.unregister(
                        'no-handle-available',
                        conf
                    );
                } else {
                    this.issuesService.registerDataProblem(
                        'no-handle-available',
                        'Żadna klamka nie pasuje do wybranego systemu i skrzydła',
                        conf,
                        {
                            extra: {
                                systemId: conf.System.id,
                                systemName: conf.System.name,
                                sashType: sash.type.name,
                                sashTypeType: sash.type.type,
                            },
                        }
                    );
                    return;
                }
            }
            if (place == 'inner' || place == 'double' || !place) {
                sash.handle = core.copy(newHandle);
            }
            if (place == 'outer' || (place == 'double' && !this.config().IccConfig.Configurators.sliding_door.outerHandles)) {
                sash.handleOuter = core.copy(newHandle);
            }
            if((conf.type === 'window' && !this.config().IccConfig.Configurators.sliding_door.outerHandles) || conf.HandleType === 'InnerLever') {
                sash.handleOuter = {};
            }
            if (validate) {
                this.valid(conf);
            }
        }
        if (
            !check
            && (Common.isUndefined(this.configurationsService.conf.addPositionToGroup)
                || !this.configurationsService.conf.addPositionToGroup)
        ) {
            this.checkIsOneHandleAndAllHasHandle(conf, noRedraw, defaultConf);
        }
        if (!noRedraw) {
            this.eventBusService.post({
                key: 'icc-redraw',
                value: 'sash',
                conf
            });
        }

        this.findColorsForHandle();
        this.locksService.findLocksBySystem();
        this.priceService.count();
        this.parametersService.count(conf);
        this.eventBusService.post({ key: 'processDependencies', value: null });
    }

    /**
     * Funkcja pobierajaca klamke dla typu skrzydła
     * @param  {object} typeId Type ID
     */
    getHandleForSashType(typeId) {
        return this.handles.filter(el => el.sash_types_ids.indexOf(typeId) > -1);
    }

    /**
     * Funkcja ustawiajaca kolor klamki wg ID
     * @param {object} handleColorId Id koloru klamki
     */
    setHandleColorById(
        handleColorId,
        sash,
        place,
        filter = (sash: ActiveSash) => true,
        isDefault = false
    ) {
        const matchedColors = this.handleColorsAll.filter(el => Number(el.id) === Number(handleColorId));
        if (matchedColors.length > 0) {
            const matchedColor = core.copy(matchedColors[0]);
            matchedColor.isDefault = isDefault;
            this.setHandleColor(matchedColor, sash, place, filter);
        }
    }

    /**
     * Funkcja ustawiajaca kolor klamki
     * @param {object} handleColor Kolor klamki
     * @param {object} sash        Skrzydło
     */
    setHandleColor(
        handleColor,
        sash,
        place,
        filter = (sash: ActiveSash) => true,
        check = false,
        noRedraw = false,
        conf = this.configurationsService.conf.Current,
        defaultConf = this.configurationsService.conf.Default,
    ) {
        if (handleColor && !handleColor.isDefault) {
            handleColor.isDefault = false;
        }

        if (!place || place == 'inner' || place == 'global') {
            if (Common.isObject(sash)) {
                if (Common.isDefined(sash.handle) && Common.isArray(sash.handle.colors_ids)) {
                    if (
                        !Common.isObject(handleColor)
                        || sash.handle.colors_ids.indexOf(handleColor.id) == -1
                    ) {
                        if (
                            Common.isDefined(defaultConf.HandleColor)
                            && defaultConf.HandleColor !== null
                            && Common.isDefined(
                                defaultConf.HandleColor.id
                            )
                            && sash.handle.colors_ids.indexOf(
                                defaultConf.HandleColor.id
                            ) > -1
                            && !defaultConf.HandleColor.isDefault
                        ) {
                            handleColor = core.copy(
                                defaultConf.HandleColor
                            );
                        } else {
                            handleColor = this.getDefaultColorForHandle(sash.handle, 'inner', conf);
                            handleColor.isDefault = true;
                        }
                    }
                    sash.handleColor = core.copy(handleColor);
                    this.priceService.count();
                }
            } else {
                if (
                    Common.isDefined(conf.Handle)
                    && Common.isArray(conf.Handle.colors_ids)
                ) {
                    if (
                        !Common.isObject(handleColor)
                        || conf.Handle.colors_ids.indexOf(
                            handleColor.id
                        ) == -1
                    ) {
                        handleColor = this.getDefaultColorForHandle(false, 'inner', conf);
                        handleColor.isDefault = true;
                    }
                    conf.HandleColor = core.copy(handleColor);
                    defaultConf.HandleColor = core.copy(handleColor);
                    this.setHandleColorForAllSashes(handleColor, place, filter, conf, defaultConf);
                    this.priceService.count();
                }
            }
        }
        if (place == 'outer' || place == 'global') {
            if (Common.isObject(sash)) {
                if (
                    Common.isDefined(sash.handleOuter)
                    && Common.isArray(sash.handleOuter?.colors_ids)
                ) {
                    if (
                        !Common.isObject(handleColor)
                        || sash.handleOuter.colors_ids.indexOf(handleColor.id) == -1
                    ) {
                        if (
                            Common.isDefined(
                                defaultConf.HandleOuterColor
                            )
                            && defaultConf.HandleOuterColor !== null
                            && Common.isDefined(
                                defaultConf.HandleOuterColor.id
                            )
                            && sash.handleOuter.colors_ids.indexOf(
                                defaultConf.HandleOuterColor.id
                            ) > -1
                            && !defaultConf.HandleOuterColor.isDefault
                        ) {
                            handleColor = core.copy(
                                defaultConf.HandleOuterColor
                            );
                        } else {
                            handleColor = this.getDefaultColorForHandle(sash.handleOuter, 'outer', conf);
                            handleColor.isDefault = true;
                        }
                    }
                    sash.handleOuterColor = core.copy(handleColor);
                    this.priceService.count();
                }
            } else {
                if (
                    Common.isDefined(conf.Handle)
                    && Common.isArray(conf.Handle.colors_ids)
                ) {
                    if (
                        !Common.isObject(handleColor)
                        || conf.Handle.colors_ids.indexOf(
                            handleColor.id
                        ) === -1
                        && !this.config().IccConfig.Configurators.sliding_door.outerHandles
                    ) {
                        handleColor = this.getDefaultColorForHandle(false, 'inner', conf);
                        handleColor.isDefault = true;
                    } else if(
                        !Common.isObject(handleColor)
                        || conf.HandleOuter.colors_ids.indexOf(
                            handleColor.id
                        ) === -1
                        && this.config().IccConfig.Configurators.sliding_door.outerHandles
                    ) {
                        handleColor = this.getDefaultColorForHandle(false, 'outer', conf);
                        handleColor.isDefault = true;
                    }
                    conf.HandleOuterColor = core.copy(
                        handleColor
                    );
                    defaultConf.HandleOuterColor = core.copy(
                        handleColor
                    );
                    this.setHandleColorForAllSashes(handleColor, place, filter, conf, defaultConf);
                    this.priceService.count();
                }
            }
        }
        if (
            !check
            && (Common.isUndefined(this.configurationsService.conf.addPositionToGroup)
                || !this.configurationsService.conf.addPositionToGroup)
        ) {
            this.checkIsOneHandleAndAllHasHandle(conf, noRedraw, defaultConf);
        }
    }

    /**
     * Funkcja ustawiajaca kolor klamki dla wszyskich skrzydeł
     * @param {object} handleColor Kolor klamki
     */
    setHandleColorForAllSashes(
        handleColor,
        place,
        filter = (sash: ActiveSash) => true,
        conf = this.configurationsService.conf.Current,
        defaultConf = this.configurationsService.conf.Default,
    ) {
        conf.Sashes.filter(filter).forEach(sash => {
            this.setHandleColor(handleColor, sash, place, filter = () => true, false, false, conf, defaultConf);
        });
    }

    /**
     * Funkcja pobierajaca domyslny kolor dla klamki
     * @param  {object} sash Skrzydło
     */
    getDefaultColorForHandle(
        handle?,
        side: 'inner' | 'outer' = 'inner',
        conf = this.configurationsService.conf.Current,
    ) {
        let color;
        if (!Common.isObject(handle)) {
            if (handle == 'outer') {
                handle = conf.HandleOuter;
            } else {
                handle = conf.Handle;
            }
        }
        if (
            Common.isObject(handle)
            && Common.isArray(handle.colors_ids)
            && Common.isArray(this.handleColorsAll)
        ) {
            const windowColorId = this.colorMappingService.getWindowColorId(
                conf,
                side
            );
            const matchedColors = this.colorMappingService.getColors(
                Number(windowColorId),
                'window',
                'accessory'
            );
            const colors = this.handleColorsAll.filter(el => handle.colors_ids.indexOf(el.id) > -1);
            const windowColors = matchedColors
                .map(m => colors.filter(c => Number(c.id) === m)[0])
                .filter(m => m);
            const whiteHandle = colors.filter(
                el => el.color && el.color.toString().toUpperCase() === 'FFFFFF'
            );
            if (Common.isArray(windowColors) && Common.isDefined(windowColors[0])) {
                color = core.copy(windowColors[0]);
            } else if (Common.isArray(whiteHandle) && Common.isDefined(whiteHandle[0])) {
                color = core.copy(whiteHandle[0]);
            } else {
                color = core.copy(colors[0]);
            }
            color.isDefault = true;
        }
        return color;
    }

    setHandleType(
        type,
        conf = this.configurationsService.conf.Current,
    ) {
        if (type === 'LeverPull') {
            conf.HandleType = 'LeverPull';
        } else if(type !== 'InnerLever' && this.config().IccConfig.Configurators.sliding_door.outerHandles) {
            conf.HandleType = 'DoubleLever';
        }
        this.validateHandleAndFixIssues();
        this.eventBusService.post({
            key: 'changedHandleType',
            value: type,
        });
    }

    outerHandleAvailable(conf = this.configurationsService.conf.Current) {
        return this.config().IccConfig.Configurators.sliding_door.outerHandles
            && ['sliding_door', 'window'].indexOf(conf.type) > -1
            && (conf.Handle?.outer_handles_id?.length > 0 || false);
    }

    /**
     * Funkcja ustawiajaca domyslna zawias dla systemu
     */
    setDefaultHingeForSystem(
        conf = this.configurationsService.conf.Current
    ) {
        this.eventBusService.post({
            key: 'processDependencies',
            value: null,
        });
        const hinges = this.findHingesBySystem(conf.System, conf).filter((k) =>
            this.isHingeBasedOnDependenciesAvailable(k.id)
        );

        if (!hinges.length) {
            this.removeExtensionsBasedOnBlockadeToConfiguration(conf);
        }

        if (
            Common.isArray(hinges) &&
            Common.isObject(hinges[0]) &&
            (!Common.isObject(conf.Hinge) ||
                (Common.isObject(conf.Hinge) &&
                hinges.map((h) => h.id).indexOf(conf.Hinge.id) == -1))
            || (!hinges.find(h => h.id === conf.Hinge.id) && hinges.length)
        ) {
            this.setHinge(hinges[0], conf);
        }
    }

    /**
     * Funkcja ustawiająca Zawiasy
     * @param {object} hinge Zawiasy
     */
    setHinge(
        hinge,
        conf = this.configurationsService.conf.Current
    ) {
        conf.Hinge = hinge;
        this.setDefaultColorForHinge(conf);
        this.priceService.count();
        this.eventBusService.post({
            key: 'processDependencies',
            value: null,
        });
    }

    /**
     * Funkcja ustawiajca kolor zawiasów
     * @param {object} color Kolor
     */
    setHingeColor(color, conf = this.configurationsService.conf.Current) {
        conf.HingeColor = color ? color : null;
    }

    /**
     * Funkcja ustawiająca domyslny kolor dla zawiasów
     */
    setDefaultColorForHinge(
        conf = this.configurationsService.conf.Current
    ) {
        const hinge = conf.Hinge;

        if (
            Common.isObject(hinge)
            && Common.isArray(hinge.colors_ids)
            && Common.isArray(this.handleColorsAll)
        ) {
            const windowColorId = this.colorMappingService.getWindowColorId(
                conf,
                'inner'
            );
            const matchedColors = this.colorMappingService.getColors(
                Number(windowColorId),
                'window',
                'accessory'
            );
            const colors = this.handleColorsAll.filter(el =>
                hinge.colors_ids.indexOf(el.id) > -1
            );

            if (conf.HingeColor?.id &&
                colors.some(c => +c.id === +conf.HingeColor.id) &&
                !conf.HingeColor.isDefault
            ) {
                return;
            }

            const colorIdsFromAccessoryMapping = this.colorMappingService.accessoryMappingColorIds;
            let color = colors.find((c) => colorIdsFromAccessoryMapping?.some(mappedColorId => mappedColorId === Number(c.id)));

            if (colorIdsFromAccessoryMapping && color) {
                this.setHingeColor(color, conf);
            } else if (Common.isArray(colors) && Common.isDefined(colors[0])) {
                const windowColors = matchedColors
                    .map(m => colors.filter(c => Number(c.id) === m)[0])
                    .filter(m => m);
                if (Common.isArray(windowColors) && Common.isDefined(windowColors[0])) {
                    color = core.copy(windowColors[0]);
                } else {
                    color = core.copy(colors[0]);
                }
                color.isDefault = true;
                this.setHingeColor(color, conf);
            }
        }
    }

    /**
     * Funkcja ustawiająca kolor dla zawiasów
     * @param  {number} hingeColorId  Id koloru
     */
    setHingeColorById(hingeColorId, isDefault = false) {
        const matchedColors = this.handleColorsAll.filter(function filterHingesColorsById(el) {
            return el.id === hingeColorId;
        });
        if (matchedColors.length > 0) {
            const color = core.copy(matchedColors[0]);
            color.isDefault = isDefault;
            this.setHingeColor(color);

            this.eventBusService.post({
                key: 'accessoryColorBasedOnMapping',
                value: { colorId: Number(matchedColors[0]?.id) , place: 'hinge'},
            });
        } else {
            this.setHingeColor(null);
        }
    }

    /**
     * Funkcja ustawiajaca klamkę dla wszystkich skrzydel
     * @param {object} newHandle Nowa klamka
     */
    setHandleInAllSashes(
        newHandle,
        place,
        filter = (sash: ActiveSash) => true,
        conf = this.configurationsService.conf.Current,
        defaultConf = this.configurationsService.conf.Default,
        validate = true
    ) {
        conf.Sashes.filter(filter).forEach(sash => {
            this.setHandle(sash, place, newHandle, false, false, false, conf, defaultConf);
        });
        if(validate) {
            this.valid(conf);
        }
    }

    getHandleHeightsForSash(sash, conf = this.configurationsService.conf.Current) {
        if (
            typeof sash !== 'undefined'
            && sash != 'default'
            && sash != 'window'
            && sash != 'door'
        ) {
            const { heightsRepository, fittingsGroove, sashFittingsHeight } = this.getHandleHeightsRepository(conf, sash);

            const handlersHeights = [];
            if (Common.isDefined(heightsRepository[0])) {
                if (parseInt(heightsRepository[0].height.default)) {
                    handlersHeights.push({
                        name: unitMMFormatter(
                            parseInt(heightsRepository[0].height.default) + fittingsGroove,
                            this.config().IccConfig,
                            1
                        ),
                        height: parseInt(heightsRepository[0].height.default) + fittingsGroove,
                    });
                }
                handlersHeights.push(
                    ...(heightsRepository[0].height.option || [])
                        .map(el => parseInt(el))
                        .filter(
                            el =>
                                el < sashFittingsHeight
                                && el > this.config().IccConfig.Configurators.minHandleHeight
                                && (!handlersHeights[0]
                                    || handlersHeights[0].height !== el + fittingsGroove)
                        )
                        .map(el => ({
                            name: unitMMFormatter(el + fittingsGroove, this.config().IccConfig, 1),
                            height: el + fittingsGroove,
                        }))
                );
                if (~~heightsRepository[0].height.middle) {
                    handlersHeights.push({
                        name: this.translateService.instant('WINDOW|½ wysokości skrzydła'),
                        height: 'middle',
                    });
                }
                if (this.config().IccConfig.Configurators.nonStandardHandleHeight) {
                    handlersHeights.push({
                        name: this.translateService.instant('WINDOW|niestandardowa'),
                        height: 'nonstandard',
                    });
                }
            }

            return handlersHeights;
        } else {
            return [];
        }
    }

    getLoweredHandleHeightForSash(sash, conf = this.configurationsService.conf.Current): HandleHeight | null {
        let loweredHandleHeight = null;
        if (
            typeof sash !== 'undefined'
            && sash != 'default'
            && sash != 'window'
            && sash != 'door'
        ) {
            const {heightsRepository} = this.getHandleHeightsRepository(conf, sash);

            if (
                this.config().IccConfig.Configurators.loweredHandlerHeights
                && this.config().preset === 'b2c'
                && heightsRepository.length > 0
                && heightsRepository[0].lowered_handle_height
            ) {
                loweredHandleHeight = {
                    name: this.translateService.instant('WINDOW|Obniżona klamka'),
                    height: heightsRepository[0].lowered_handle_height
                }
            }

        }
        return loweredHandleHeight;
    }

    private getHandleHeightsRepository(conf: WindowActiveConfiguration | DoorActiveConfiguration, sash: any) {
        const frame = conf.Frames.find(f => sash.frameId === f.id);
        const bottomProfileId = sash.nearMullions.bottom !== -1
            ? conf.Mullions.find(mullion => mullion.id === sash.nearMullions.bottom)
                .profileId
            : frame.frame[0].profileId;
        const bottomProfile = conf.UsedProfiles.find(el => el.id === bottomProfileId);

        const sashFrameProfile = conf.UsedProfiles.find(
            el => el.id === sash.frame.bottom.profileId
        );
        const sashFrameRebate = !this.config().IccConfig.Configurators.handlesWithRebateHeight && sashFrameProfile
            ? Number(sashFrameProfile.rebateWidth) * 2 || 0
            : 0;

        const sashFrame = conf.drawData.sashFrame.find(o => o.sashId === sash.id);
        const sashFittingsHeight = sashFrame
            ? sashFrame.outer.rect.height - sashFrameRebate
            : 0;
        const fittingsGroove = this.config().IccConfig.Configurators.handlesAddFittingsGroove && bottomProfile
            ? bottomProfile.spaceSash
            : 0;

        let handleHeightData = [];
        if (Common.isArray(this.handleHeightService.handlerHeights)) {
            handleHeightData = this.handleHeightService.handlerHeights.filter(
                el => parseFloat(el.window_height_from) <= sashFittingsHeight
                    && parseFloat(el.window_height_to) >= sashFittingsHeight
                    && el.window_lines
                    && el.window_lines.indexOf(this.configurationsService.conf.Current.System.id)
                    > -1
            );
        }
        if (handleHeightData.length) {
            this.handleHeightService.systemHeight = true;
        }
        if (!handleHeightData.length) {
            handleHeightData = this.handleHeightService.handlerHeights.filter(
                el => parseFloat(el.window_height_from) <= sashFittingsHeight
                    && parseFloat(el.window_height_to) >= sashFittingsHeight
                    && el.system_types
                    && el.system_types.indexOf(conf.System.type) > -1
            );
        }

        const handleHeightBalcony = handleHeightData.filter(
            el => el.balcony_window == conf.Balcony
        );
        let heightsRepository = [];
        if (Common.isDefined(handleHeightBalcony[0]) && sash.nearMullions.bottom == -1) {
            heightsRepository = handleHeightBalcony;
        } else if (Common.isDefined(handleHeightData[0])) {
            heightsRepository = handleHeightData;
        }
        return { heightsRepository, fittingsGroove, sashFittingsHeight };
    }

    /**
     * Funkcja ustawiania wysokości klamki
     * @param {object} sash   Skrzydło
     * @param {object} height Wysokośc
     */
    setHandleHeight(sash, height, customHeight) {
        if (
            Common.isDefined(sash.handle)
            && Common.isDefined(sash.handle.id)
            && this.noHandleTypes.indexOf(sash.type.type) == -1
        ) {
            if (height == 'middle') {
                sash.rHandleY = sash.rHeight / 2;
                sash.defaultHandleHeight = isNaN(
                    Number(this.handleHeightService.defaultHeight(sash, this.configurationsService.conf.Current))
                );
                sash.handleHeightType = 'middle';
            } else if (height == 'nonstandard') {
                sash.rHandleY = customHeight;
                sash.defaultHandleHeight = false;
                sash.handleHeightType = 'nonstandard';
            } else {
                sash.rHandleY = height;
                sash.defaultHandleHeight =
                    this.handleHeightService.defaultHeight(sash, this.configurationsService.conf.Current)
                    == height;
                sash.handleHeightType = 'standard';
            }
            this.checkIsOneHandleAndAllHasHandle(this.configurationsService.conf.Current);
            this.eventBusService.post({
                key: 'icc-redraw',
                value: 'sash',
            });
        }
        this.priceService.count();
    }

    /**
     * Funkcja znajdujaca klamki wg systemu
     * @param  {object} system  System
     * @param  {object} fitting Okucie
     * @return {object}         Klamki
     */
    findHandlesBySystem(
        system = undefined,
        fitting = undefined,
        conf = this.configurationsService.conf.Current
    ) {
        if (Common.isUndefined(conf) && !(system)) {
            return;
        }

        let systemPassed = true;
        if (!system) {
            system = conf.System;
            fitting = conf.Fitting;
            systemPassed = false;
        }

        this.handles = [];
        this.handlesForSelect = [];
        Common.forEach(this.allHandles, handle => {
            const availableSystemsIds = handle.window_lines_ids;
            const availableFittingsIds = handle.window_fittings_ids;
            if (!Common.isUndefined(fitting)) {
                if (availableSystemsIds.indexOf(system.id) > -1) {
                    const availableInFitting = availableFittingsIds.indexOf(fitting.id) > -1;
                    const fittingRelation = handle?.windowFittingsWithRelationType?.find((relation: WindowFittingRelation) =>
                        Number(relation.windowFittingId) === Number(fitting.id)
                    ) || false;
                    if (availableInFitting || !this.config().IccConfig.Configurators.filteringHandlesForRC) {
                        if (Common.isArray(handle.colors_ids)) {
                            handle.colors_ids = handle.colors_ids.sort((a, b) =>
                                ~~a > ~~b ? 1 : -1
                            );
                        }
                        if(this.config().IccConfig.Configurators.sizeRangesFitting) {
                            if(fittingRelation && fittingRelation.relationType !== 'blockade') {
                                this.handles.push(handle);
                            }
                        } else {
                            this.handles.push(handle);
                        }
                    }
                    if (availableInFitting || !this.config().IccConfig.Configurators.selectHandleOnChangeFitting) {
                        if (Common.isArray(handle.colors_ids)) {
                            handle.colors_ids = handle.colors_ids.sort((a, b) =>
                                ~~a > ~~b ? 1 : -1
                            );
                        }
                        if(this.config().IccConfig.Configurators.sizeRangesFitting) {
                            if(fittingRelation && fittingRelation.relationType !== 'blockade') {
                                this.handlesForSelect.push(handle);
                            }
                        } else {
                            this.handlesForSelect.push(handle);
                        }
                    }
                }
            }
        });
        if (!systemPassed) {
            this.eventBusService.post({
                key: 'refreshHandlesColors',
                value: null
            });
        } else {
            return this.handles;
        }
    }

    /**
     * Funkcja znajdujaca kolor dla klamki
     */
    findColorsForHandle() {
        if (Common.isUndefined(this.configurationsService.conf)) {
            return;
        }
        this.handleColors = [];
        if (Common.isArray(this.handleColorsAll)) {
            if (Common.isObject(this.configurationsService.conf.Current.Handle)) {
                const handlesColors = this.configurationsService.conf.Current.Handle.colors_ids;
                if (Common.isArray(handlesColors)) {
                    this.handleColors = this.getFilteredHandleColors();
                    this.eventBusService.post({
                        key: 'refreshHandlesColors',
                        value: null
                    });
                    // Set default handle color if needed
                    if (
                        Common.isUndefined(this.configurationsService.conf.Current.HandleColor)
                        || Common.isUndefined(this.configurationsService.conf.Current.HandleColor.id)
                        || !this.selectedHandleColorAvailable()
                    ) {
                        this.configurationsService.conf.Current.HandleColor = core.copy(
                            this.getDefaultColorForHandle()
                        );
                    }
                } else {
                    this.handleColors = [];
                    this.eventBusService.post({
                        key: 'refreshHandlesColors',
                        value: null
                    });
                }
            }
        }
    }

    getFilteredHandleColors() {
        if (
            !Common.isUndefined(this.configurationsService.conf)
            && Common.isArray(this.handleColorsAll)
            && Common.isObject(this.configurationsService.conf.Current.Handle)
        ) {
            const handlesColors = this.configurationsService.conf.Current.Handle.colors_ids;
            if (Common.isArray(handlesColors)) {
                return this.handleColorsAll.filter(el => handlesColors.indexOf(el.id) > -1);
            }
        }

        return [];
    }

    selectedHandleColorAvailable(conf = this.configurationsService.conf.Current) {
        const filteredHandleColors = this.getFilteredHandleColors();
        const selectedHandleColorId = Number(conf.HandleColor.id);

        return filteredHandleColors.some(c => Number(c.id) === selectedHandleColorId);
    }

    reviseHandles(
        sash,
        noRedraw,
        conf = this.configurationsService.conf.Current,
        defaultConf = this.configurationsService.conf.Default,
    ) {
        if (
            !sash.handle
            || !sash.handle.id
            || Common.isUndefined(core.fId(this.handles, sash.handle.id))
        ) {
            this.setDefaultHandleForSash(conf, sash, 'handle');

        }
        if (
            !sash.handleOuter
            || !sash.handleOuter.id
            || Common.isUndefined(core.fId(this.handles, sash.handleOuter.id))
        ) {
            this.setDefaultHandleForSash(conf, sash, 'handleOuter');
        }
        if (
            !sash.handleColor
            || !sash.handleColor.id
            || Common.isUndefined(
                core.fId(
                    this.handleColorsAll.filter(
                        hc =>
                            sash.handle.colors_ids && sash.handle.colors_ids.indexOf(hc.id) > -1
                    ),
                    sash.handleColor.id
                )
            )
            || sash.handleColor.isDefault
        ) {
            this.setHandleColor(null, sash, 'inner', undefined, true, noRedraw, conf, defaultConf);
        }
        if (
            !sash.handleOuterColor
            || !sash.handleOuterColor.id
            || Common.isUndefined(
                core.fId(
                    this.handleColorsAll.filter(
                        hc =>
                            sash.handleOuter.colors_ids
                            && sash.handleOuter.colors_ids.indexOf(hc.id) > -1
                    ),
                    sash.handleOuterColor.id
                )
            )
            || sash.handleOuterColor.isDefault
        ) {
            this.setHandleColor(null, sash, 'outer', undefined, true, noRedraw, conf, defaultConf);
        }
    }

    setOneHandle(place, firstHandle, type) {
        if (place[type]) {
            place[type] = firstHandle[type];
        }
        if (place[type + 'Color']) {
            place[type + 'Color'] = firstHandle[type + 'Color'];
        }
    }

    checkIsOneHandleSashType(
        conf: WindowActiveConfiguration | DoorActiveConfiguration,
        firstHandle: {window, windowColor, door, doorColor }, 
        firstHandleOuter: {window, windowColor, door, doorColor },
        sash: ActiveSash, 
        type: 'window' | 'door'
    ) {
        if (
            (!firstHandle[type]
                || !firstHandle[type].id
                || !firstHandle[type + 'Color']
                || !firstHandle[type + 'Color'].id)
            && sash.handle
            && sash.handle.id
            && sash.handleColor
            && sash.handleColor.id
        ) {
            firstHandle[type] = core.copy(sash.handle);
            firstHandle[type + 'Color'] = core.copy(sash.handleColor);
        }
        if (
            (!firstHandleOuter[type]
                || !firstHandleOuter[type].id
                || !firstHandleOuter[type + 'Color']
                || !firstHandleOuter[type + 'Color'].id)
            && sash.handleOuter
            && sash.handleOuter.id
            && sash.handleOuterColor
            && sash.handleOuterColor.id
        ) {
            firstHandleOuter[type] = core.copy(sash.handleOuter);
            firstHandleOuter[type + 'Color'] = core.copy(sash.handleOuterColor);
        }
        if (
            (firstHandle[type] && firstHandle[type].id && Number(sash.handle.id) !== Number(firstHandle[type].id))
            || (conf.HandleType === 'InnerLever'
                && firstHandleOuter[type]
                && firstHandleOuter[type].id
                && Number(sash.handleOuter.id) !== Number(firstHandleOuter[type].id))
        ) {
            conf.OneHandleSash[type] = false;
            conf.OneHandleOuterSash[type] = false;
        }
        if (
            (firstHandle[type + 'Color']
                && firstHandle[type + 'Color'].id
                && Number(sash.handleColor.id) !== Number(firstHandle[type + 'Color'].id))
            || (conf.HandleType === 'InnerLever'
                && firstHandleOuter[type + 'Color']
                && firstHandleOuter[type + 'Color'].id
                && Number(sash.handleOuterColor.id) !== Number(firstHandleOuter[type + 'Color'].id))
        ) {
            conf.OneHandleSash[type + 'Color'] = false;
            conf.OneHandleOuterSash[type + 'Color'] = false;
        }
    }

    /**
     * Funkcja sprawdzajaca checkIsOneHandleAndAllHasHandle
     * @param  {object} conf     Konfiguracja
     * @param  {object} noRedraw noRedraw
     */
    checkIsOneHandleAndAllHasHandle(
        conf = this.configurationsService.conf.Current,
        noRedraw?,
        defaultConf = this.configurationsService.conf.Default,
    ) {
        if (Common.isUndefined(conf) || conf.Sashes.length === 0 || !conf.System?.id) {
            return;
        }
        conf.OneHandle = true;
        conf.OneHandleSash = {
            window: true,
            windowColor: {},
            door: true,
            doorColor: {},
        };
        conf.OneHandleOuterSash = {
            window: true,
            windowColor: {},
            door: true,
            doorColor: {},
        };
        conf.OneHandlesHeight = true;
        conf.HasHandle = false;

        let handleHeight;
        const firstHandle = {
            window: {},
            windowColor: {},
            door: {},
            doorColor: {},
        };
        const firstHandleOuter = {
            window: {},
            windowColor: {},
            door: {},
            doorColor: {},
        };
        conf.Sashes.forEach(sash => {
            if (this.noHandleTypes.indexOf(sash.type.type) > -1) {
                sash.handle = {};
                sash.handleOuter = {};
                sash.rHandleY = null;
            } else {
                this.reviseHandles(sash, noRedraw, conf, defaultConf);
                if (sash.handle && sash.handle.id) {
                    conf.HasHandle = true;
                }
                if (['DRA', 'DOA'].indexOf(sash.type?.type) === -1) {
                    this.checkIsOneHandleSashType(
                        conf,
                        firstHandle,
                        firstHandleOuter,
                        sash,
                        'window'
                    );
                }

                if (['DRA', 'DOA'].indexOf(sash.type?.type) > -1) {
                    this.checkIsOneHandleSashType(
                        conf, 
                        firstHandle, 
                        firstHandleOuter, 
                        sash, 
                        'door'
                    );
                }

                if (!handleHeight && sash.rHandleY) {
                    handleHeight = sash.rHandleY;
                    conf.HandleHeight = handleHeight;
                }
                if (handleHeight && Number(sash.rHandleY) !== Number(handleHeight)) {
                    conf.OneHandlesHeight = false;
                }
            }
        });

        this.setOneHandle(conf.OneHandleSash, firstHandle, 'window');
        this.setOneHandle(conf.OneHandleOuterSash, firstHandleOuter, 'window');

        this.setOneHandle(conf.OneHandleSash, firstHandle, 'door');
        this.setOneHandle(conf.OneHandleOuterSash, firstHandleOuter, 'door');

        if (
            !conf.OneHandleSash.window
            || !conf.OneHandleSash.door
            || !conf.OneHandleSash.windowColor
            || !conf.OneHandleSash.doorColor
            || (conf.OwnedSashesTypes.doorActive
                && (conf.OwnedSashesTypes.window
                    && conf.Sashes.some(
                        s =>
                            !['F', 'FF', 'OFF', 'DRA', 'DRP', 'DOA', 'DOP'].includes(
                                s.type.type
                            )
                    ))
                && (conf.OneHandleSash.window.id !== conf.OneHandleSash.door.id
                    || conf.OneHandleSash.windowColor.id !== conf.OneHandleSash.doorColor.id))
        ) {
            conf.OneHandle = false;
        }
        if (conf.OneHandleSash.door && conf.OneHandleSash.door.id) {
            conf.Handle = core.copy(conf.OneHandleSash.door);
            conf.HandleColor = core.copy(conf.OneHandleSash.doorColor);
            if(conf.Handle.isDefault) {
                this.configurationsService.conf.Default.Handle = core.copy(conf.OneHandleSash.door);
                this.configurationsService.conf.Default.HandleColor = core.copy(
                    conf.OneHandleSash.doorColor
                );
            }
        } else if (conf.OneHandleSash.window && conf.OneHandleSash.window.id) {
            conf.Handle = core.copy(conf.OneHandleSash.window);
            conf.HandleColor = core.copy(conf.OneHandleSash.windowColor);
            if(conf.Handle.isDefault) {
                this.configurationsService.conf.Default.Handle = core.copy(conf.OneHandleSash.window);
                this.configurationsService.conf.Default.HandleColor = core.copy(
                    conf.OneHandleSash.windowColor
                );
            }
        }
        if (conf.OneHandleOuterSash.door && conf.OneHandleOuterSash.door.id) {
            conf.HandleOuter = core.copy(conf.OneHandleOuterSash.door);
            conf.HandleOuterColor = core.copy(conf.OneHandleOuterSash.doorColor);
            if(conf.HandleOuter.isDefault) {
                this.configurationsService.conf.Default.HandleOuter = core.copy(
                    conf.OneHandleOuterSash.door
                );
                this.configurationsService.conf.Default.HandleOuterColor = core.copy(
                    conf.OneHandleOuterSash.doorColor
                );
            }
        } else if (conf.OneHandleOuterSash.window && conf.OneHandleOuterSash.window.id) {
            conf.HandleOuter = core.copy(conf.OneHandleOuterSash.window);
            conf.HandleOuterColor = core.copy(conf.OneHandleOuterSash.windowColor);
            if(conf.HandleOuter.isDefault) {
                this.configurationsService.conf.Default.HandleOuter = core.copy(
                    conf.OneHandleOuterSash.window
                );
                this.configurationsService.conf.Default.HandleOuterColor = core.copy(
                    conf.OneHandleOuterSash.windowColor
                );
            }
        }
        this.locksService.findLocksBySystem();
        this.priceService.count();
    }

    /**
     * Funkcja odświezajaca typ
     * @param  {object} conf Konfiguracja
     */
    refreshTypes(conf) {
        if (Common.isUndefined(conf)) {
            return false;
        }
        this.sashTypes = this.getSashTypes(conf);

        this.checkHandleType(conf);
        if (conf.Sashes.every(sash => ['F', 'FF', 'OFF'].indexOf(sash.type.type) > -1)) {
            this.isMatch.ref = false;
            conf.Hinge = null;
            conf.HingeColor = null;
        } else {
            this.isMatch.ref = true;
            this.findHingesBySystem(conf.System, conf);
            this.setDefaultHingeForSystem(conf);
        }
    }

    getSashTypes(conf = this.configurationsService.conf.Current) {
        return conf.Sashes.map(sash => {
            if (this.noHandleTypes.indexOf(sash.type.type) === -1) {
                return sash.type.id;
            }
        }).filter((el, pos, self) => self.indexOf(el) === pos);
    }

    checkHandleType(conf = this.configurationsService.conf.Current) {
        const height = conf.Height;
        const activeSashesOnBottom = conf.Sashes.filter(sash => sash?.type?.type !== 'FF' && sash?.type?.type !== 'F').some(sash => Number(sash.rHeight + sash.ry) === Number(height));
        if(this.config().IccConfig.Configurators.sliding_door.outerHandles && ['sliding_door', 'window'].indexOf(conf.type) > -1) {
            const hasDoubleLever = this.hasOuterHandles('DoubleLever');
            const hasLeverPull = this.hasOuterHandles('LeverPull');
            if(conf.HandleType !== 'InnerLever' && !activeSashesOnBottom) {
                conf.HandleType = 'InnerLever';
            }
            if((conf.HandleType === 'DoubleLever' && !hasDoubleLever) || (conf.HandleType === 'LeverPull' && !hasLeverPull) ) {
                conf.HandleType = 'InnerLever';
            }
        }
    }

    /**
     * Funkcja filtra klamek ze względu na położenie i typ
     * @param  {object} handle Klamka
     * @return {bool}          Czy klamka pasuje do położenia i typu
     */
    filterHandlesByPlaceAndType(place: HandlesPlace, type?: HandlesType, conf?) {
        return (handle) => this.checkIfHandleAvailableInPlaceAndType(handle, place, type, conf);
    }

    /**
     * Funkcja sprawdzająca czy klamka jest dostępna w danym położeniu i typie
     * @param  {object} handle Klamka
     * @param  {string} place  Położenie
     * @param  {string} type   Typ
     * @return {bool}          Czy klamka pasuje do położenia i typu
     */
    checkIfHandleAvailableInPlaceAndType(
        handle,
        place: HandlesPlace = 'double',
        type: HandlesType = this.configurationsService.conf.Current.HandleType,
        conf = this.configurationsService.conf.Current
    ) {
        if (!Common.isObject(handle)) {
            return false;
        }

        if (this.config().IccConfig.Configurators.door.version === 1) {
            return true;
        } else {
            const outerHandleAvailable = this.outerHandleAvailable(conf);
            if(['outer', 'outerLever'].indexOf(place) > -1 && type === 'DoubleLever' && outerHandleAvailable) {
                return conf.Handle.outer_handles_id.some(handleId => Number(handleId) === Number(handle.id) && handle.handle_type !== 'pull');
            }
            if(['outer', 'outerPull'].indexOf(place) > -1 && type === 'LeverPull' && outerHandleAvailable) {
                return conf.Handle.outer_handles_id.some(handleId => Number(handleId) === Number(handle.id) && handle.handle_type === 'pull');
            }
            if(['outer', 'outerLever', 'outerPull'].indexOf(place) === -1 && this.config().IccConfig.Configurators.sliding_door.outerHandles && ['sliding_door', 'window'].indexOf(conf.type) > -1) {
                if(conf.HandleType === 'DoubleLever' && !this.handleHasOuterHandleType(handle, 'DoubleLever')) {
                    return false;
                }
                if(conf.HandleType === 'LeverPull' && !this.handleHasOuterHandleType(handle, 'LeverPull')) {
                    return false;
                }
            }
            const isNotDoorAndInnerOrOuterLever = conf.type !== 'door' && (handle.handle_type === 'innerLever' || handle.handle_type === 'outerLever');
            const isInnerLever = (place === 'innerLever' || place === 'inner') && type === 'InnerLever' && handle.handle_type === 'innerLever';
            const isDoubleLever = (place === 'innerLever' || place === 'inner' || place === 'outer' || place === 'double') && type === 'DoubleLever' && 
                (handle.handle_type === 'doubleLever' || handle.handle_type === 'leverKnob' || handle.handle_type === 'doubleKnob' || handle.handle_type === 'leverPull');
            const isLeverPull = (place === 'innerLever' || place === 'inner') && type === 'LeverPull' && 
                (handle.handle_type === 'doubleLever' || handle.handle_type === 'doubleKnob');
            const isDoublePullInner = (place === 'innerPull' || place === 'inner') && type === 'DoublePull' && handle.handle_type === 'pull';
            const isPullOuter = (place === 'outerPull' || place === 'outer' || place === 'double') && 
                (type === 'LeverPull' || type === 'DoublePull') && handle.handle_type === 'pull';

            if (isNotDoorAndInnerOrOuterLever || isInnerLever || isDoubleLever || isLeverPull || isDoublePullInner || isPullOuter) {
                return true;
            }

            return false;
        }
    }

    /**
     * Okno modalne z wyborem klamki
     * @param  {object} sash      Skrzydło
     * @param  {string} place     Położenie
     * @param  {string} type      Typ
     */
    openModalHandle(sash, place, type) {
        const conf = this.configurationsService.conf.Current;
        const doorTypes = ['DRA', 'DOA'];
        let filter = (sash: ActiveSash) => true;
        if (sash === 'window') {
            filter = s => doorTypes.indexOf(s.type.type) === -1;
        } else if (sash === 'door') {
            filter = s => doorTypes.indexOf(s.type.type) > -1;
        }
        let maxHandleHeight = null;
        const minHandleHeight = this.config().IccConfig.Configurators.minHandleHeight;
        if (
            typeof sash !== 'undefined'
            && sash !== 'default'
            && sash !== 'window'
            && sash !== 'door'
        ) {
            const sashFrameProfile = conf.UsedProfiles.find(el => el.id === sash.frame.bottom.profileId);
            const sashFrameRebate = !this.config().IccConfig.Configurators.handlesWithRebateHeight && sashFrameProfile
                ? Number(sashFrameProfile.rebateWidth) * 2 || 0
                : 0;

            const sashFrame = conf.drawData.sashFrame.find(o => o.sashId === sash.id);
            const sashFittingsHeight = sashFrame
                ? sashFrame.outer.rect.height - sashFrameRebate
                : 0;
            maxHandleHeight = sashFittingsHeight;
        }
        const modalInstance = this.modalService.open({
            templateUrl: 'modalHandle.html',
            controller: 'ModalHandleCtrl as mhandle',
            pageComponent: HandlesPageComponent,
            resolve: {
                handles: () => {
                    let handles = [];

                    if (
                        typeof sash === 'undefined'
                        || sash == 'default'
                        || sash == 'window'
                        || sash == 'door'
                    ) {
                        this.sashTypes = conf.Sashes.filter(
                            s => this.noHandleTypes.indexOf(s.type.type) == -1
                        )
                            .filter(filter)
                            .map(s => s.type.id)
                            .filter((el, pos, self) => self.indexOf(el) == pos);
                        handles = this.handles.filter((el) => {
                            const handleNotAvailable =  conf.Handle?.outer_handles_id?.indexOf(el.id) === -1;
                            if(this.outerHandleAvailable() && place === 'outerLever' && (handleNotAvailable || el.handle_type === 'pull')) {
                                return false;
                            }
                            if(this.outerHandleAvailable() && place === 'outerPull' && handleNotAvailable && el.handle_type !== 'pull') {
                                return false;
                            }
                            if(this.config().IccConfig.Configurators.sliding_door.outerHandles && place === 'innerLever' ) {
                                if(conf.HandleType === 'DoubleLever' && !this.handleHasOuterHandleType(el, 'DoubleLever')) {
                                    return false;
                                }
                                if(conf.HandleType === 'LeverPull' && !this.handleHasOuterHandleType(el, 'LeverPull')) {
                                    return false;
                                }
                            }
                            const availableSashTypesIds = el.sash_types_ids;
                            for (let j = 0; j < this.sashTypes.length; j++) {
                                if (Common.isUndefined(this.sashTypes[j])) {
                                    continue;
                                }
                                if (availableSashTypesIds.indexOf(this.sashTypes[j]) === -1) {
                                    return false;
                                }
                            }
                            return true;
                        });
                    } else {
                        handles = this.handles.filter(function filterHandlesForSash(el) {
                            const availableSashTypesIds = el.sash_types_ids;
                            if (
                                !Common.isUndefined(sash.type)
                                && !Common.isUndefined(sash.type.id)
                            ) {
                                if (availableSashTypesIds.indexOf(sash.type.id) > -1) {
                                    return true;
                                }
                            }
                            return false;
                        });
                    }

                    handles = handles.filter(this.filterHandlesByPlaceAndType(place, type, conf));

                    return handles;
                },
                selHandle: () => {
                    if (typeof sash === 'undefined' || sash == 'default') {
                        if (place == 'outerPull' || place === 'outerLever') {
                            return this.configurationsService.conf.Current.HandleOuter;
                        } else {
                            return this.configurationsService.conf.Current.Handle;
                        }
                    } else if (sash == 'window') {
                        if (place == 'outerPull') {
                            return this.configurationsService.conf.Current.OneHandleOuterSash.window;
                        } else {
                            return this.configurationsService.conf.Current.OneHandleSash.window;
                        }
                    } else if (sash == 'door') {
                        if (place == 'outerPull') {
                            return this.configurationsService.conf.Current.OneHandleOuterSash.door;
                        } else {
                            return this.configurationsService.conf.Current.OneHandleSash.door;
                        }
                    } else {
                        if (place == 'outerPull') {
                            return sash.handleOuter;
                        } else {
                            return sash.handle;
                        }
                    }
                },
                handleHeights: () => this.getHandleHeightsForSash(sash),
                loweredHandleHeight: () => this.getLoweredHandleHeightForSash(sash),
                handleY: () => sash.rHandleY,
                sashHeight: () => sash.rHeight,
                showHeightSelect: () => {
                    if (
                        typeof sash === 'undefined'
                        || sash == 'default'
                        || sash == 'window'
                        || sash == 'door'
                    ) {
                        return false;
                    } else {
                        return true;
                    }
                },
                handleCategories: () => this.handleCategories,
                handleSubcategories: () => this.handleSubcategories,
                handleData: () => this.modalHandleData,
                handleColors: () => this.handleColorsAll,
                selHandleColor: () => {
                    if (typeof sash === 'undefined' || sash == 'default') {
                        if (place == 'outerPull' || place === 'outerLever') {
                            return this.configurationsService.conf.Current.HandleOuterColor;
                        } else {
                            return this.configurationsService.conf.Current.HandleColor;
                        }
                    } else if (sash == 'window') {
                        if (place == 'outerPull') {
                            return this.configurationsService.conf.Current.OneHandleOuterSash
                                .windowColor;
                        } else {
                            return this.configurationsService.conf.Current.OneHandleSash.windowColor;
                        }
                    } else if (sash == 'door') {
                        if (place == 'outerPull') {
                            return this.configurationsService.conf.Current.OneHandleOuterSash.doorColor;
                        } else {
                            return this.configurationsService.conf.Current.OneHandleSash.doorColor;
                        }
                    } else {
                        if (place == 'outerPull') {
                            return sash.handleOuterColor;
                        } else {
                            return sash.handleColor;
                        }
                    }
                },
                selHandleOuterColor: () => {
                    if (typeof sash === 'undefined' || sash == 'default') {
                        return this.configurationsService.conf.Current.HandleOuterColor;
                    } else if (sash == 'window') {
                        return this.configurationsService.conf.Current.OneHandleOuterSash.windowColor;
                    } else if (sash == 'door') {
                        return this.configurationsService.conf.Current.OneHandleOuterSash.doorColor;
                    } else {
                        return sash.handleOuterColor;
                    }
                },
                b2c: () => this.userService.b2c,
                sashType: () => {
                    if (
                        typeof sash === 'undefined'
                        || sash == 'default'
                        || sash == 'window'
                        || sash == 'door'
                    ) {
                        return null;
                    } else {
                        return sash.type.type;
                    }
                },
                position: () => {
                    if (
                        typeof sash === 'undefined'
                        || sash == 'default'
                        || sash == 'window'
                        || sash == 'door'
                    ) {
                        return null;
                    } else {
                        return sash.type.handle_position;
                    }
                },
                availColorOuter: () =>
                    place == 'innerLever'
                    && this.configurationsService.conf.Current.HandleType == 'DoubleLever'
                    && !this.outerHandleAvailable()
                    && ((this.config().IccConfig.Configurators.door.availHandleColorOuter
                        && this.configurationsService.conf.Current.type == 'door')
                        || this.configurationsService.conf.Current.type != 'door'),
                place: () => place,
                handleHeightType: () => sash.handleHeightType,
                maxHandleHeight: () => maxHandleHeight,
                minHandleHeight: () => minHandleHeight,
                systemHeight: () => this.handleHeightService.systemHeight,
            },
        });

        modalInstance.result.then(selectedData => {
            if (Common.isObject(selectedData) && Common.isDefined(selectedData.handle)) {
                const selectedHandle = {...selectedData.handle, isDefault: false};
                
                if (
                    typeof sash === 'undefined'
                    || sash === 'default'
                    || sash === 'window'
                    || sash === 'door'
                ) {
                    if (place == 'innerLever' || place == 'innerPull' || !place) {
                        if(this.config().IccConfig.Configurators.sliding_door.outerHandles
                            && ['sliding_door', 'window'].indexOf(conf.type) > -1
                            && !selectedHandle?.outer_handles_id?.length
                        ) {
                            this.configurationsService.conf.Current.HandleType = 'InnerLever';
                            this.setHandleType('InnerLever');
                        }
                        this.setHandleInAllSashes(selectedHandle, 'inner', filter);
                        this.setHandleColorById(
                            selectedData.color,
                            undefined,
                            'inner',
                            filter,
                            selectedData.defaultColor
                        );
                        if (!selectedData.colorOuter){
                            this.setHandleColorById(
                                selectedData.color,
                                undefined,
                                'outer',
                                filter,
                                selectedData.defaultColor
                            );
                        }
                        if(this.config().IccConfig.Configurators.sliding_door.outerHandles) {
                            const outerHandle = this.checkOuterHandle(selectedHandle);
                            this.setHandleInAllSashes(outerHandle, 'outer', filter);
                        } else {
                            this.setHandleInAllSashes(selectedHandle, 'outer', filter);
                        }
                    }
                    if (
                        (place == 'innerLever'
                            && this.configurationsService.conf.Current.HandleType == 'DoubleLever')
                        || place == 'outerPull' || place === 'outerLever'
                    ) {
                        if(!(this.config().IccConfig.Configurators.sliding_door.outerHandles && place === 'innerLever')) {
                            this.setHandleColorById(
                                selectedData.colorOuter,
                                undefined,
                                'outer',
                                filter,
                                selectedData.defaultColor
                            );
                        }
                        if(!this.config().IccConfig.Configurators.sliding_door.outerHandles || ['outerPull', 'outerLever'].indexOf(place) > -1) {
                            this.setHandleInAllSashes(selectedData.handle, 'outer', filter);
                            this.setHandleColorById(
                                selectedData.colorOuter,
                                undefined,
                                'outer',
                                filter,
                                selectedData.defaultColor
                            );
                        } else {
                            const outerHandle = this.checkOuterHandle(selectedHandle);
                            this.setHandleInAllSashes(outerHandle, 'outer', filter);
                        }
                    }
                } else {
                    if (place == 'innerLever' || place == 'innerPull' || !place) {
                        this.setHandle(sash, 'inner', selectedHandle);
                        this.setHandleColorById(
                            selectedData.color,
                            sash,
                            'inner',
                            undefined,
                            selectedData.defaultColor
                        );
                    }
                    if (
                        (place == 'innerLever'
                            && this.configurationsService.conf.Current.HandleType == 'DoubleLever')
                        || place == 'outerPull'
                    ) {
                            this.setHandleColorById(
                                selectedData.colorOuter,
                                sash,
                                'outer',
                                undefined,
                                selectedData.defaultColor
                            );
                        if(!this.config().IccConfig.Configurators.sliding_door.outerHandles || place === 'outerPull') {
                            this.setHandleInAllSashes(selectedData.handle, 'outer', filter);
                            this.setHandle(sash, 'outer', selectedData.handle);
                        } else {
                            const outerHandle = this.checkOuterHandle(selectedData.handle);
                            this.setHandleInAllSashes(outerHandle, 'outer', filter);
                        }
                    }
                    if (Common.isDefined(selectedData.height)) {
                        this.setHandleHeight(sash, selectedData.height, selectedData.customHeight);
                    }
                    // ustawianie polozenia klamki, jezeli jest to okno uchylne
                    if (selectedData.position && sash.type.type == 'K') {
                        sash.type.handle_position = selectedData.position;
                        this.constructionLimitationService.findReinforcement(
                            this.configurationsService.conf.Current
                        );
                        this.eventBusService.post({
                            key: 'icc-redraw',
                            value: 'sash',
                        });
                    }
                }
                this.modalHandleData.selectedCategory = selectedData.category;
                this.modalHandleData.selectedSubcategory = selectedData.subcategory;

                this.eventBusService.post({ key: 'processDependencies', value: null });
            }
            this.eventBusService.post({
                key: 'icc-redraw',
                value: 'frame',
            });
            this.priceService.count();
            this.timeLimitService.count();
        });
        modalInstance.closed.then(() => {
            if (this.config().IccConfig.Configurators.tutorialAvailable) {
                this.eventBusService.post({
                    key: 'tutorialSteps',
                    value: 'getStepImg',
                });
            }
        });
    }

    checkOuterHandle(outerHandle) {
        const conf = this.configurationsService.conf.Current;
        const handle = conf.Handle;
        const handleType = this.configurationsService.conf.Current.HandleType;
        const handles = this.handles.filter(this.filterHandlesByPlaceAndType('outer', handleType));
        const outerHandleInInnerHandle = handles.some(h => Number(h.id) === Number(outerHandle.id));
        const currentOuterHandle = conf.HandleOuter;
        const currentOuterHandleInInnerHandle = handles.some(h => Number(h.id) === Number(currentOuterHandle.id));
        if(!handle.outer_handles_id.length || handleType === 'InnerLever') {
            return null;
        } else if(currentOuterHandleInInnerHandle){
            return currentOuterHandle;
        } else if(outerHandleInInnerHandle) {
            return outerHandle;
        } else if(handles.length > 0) {
            return handles[0];
        }
        return null;
    }

    /**
     * Okno modalne z wyborem klamki
     * @param  {object} sash      Skrzydło
     * @param  {string} place     Położenie
     * @param  {string} type      Typ
     */
    openModalHandleOptions(sash, place, type) {
        const conf = this.configurationsService.conf.Current;
        const doorTypes = ['DRA', 'DOA'];
        let filter = (sash: ActiveSash) => true;
        if (sash == 'window') {
            filter = s => doorTypes.indexOf(s.type.type) == -1;
        } else if (sash == 'door') {
            filter = s => doorTypes.indexOf(s.type.type) > -1;
        }
        let maxHandleHeight = null;
        const minHandleHeight = this.config().IccConfig.Configurators.minHandleHeight;
        if (
            typeof sash !== 'undefined'
            && sash != 'default'
            && sash != 'window'
            && sash != 'door'
        ) {
            const sashFrameProfile = conf.UsedProfiles.find(el => el.id === sash.frame.bottom.profileId);
            const sashFrameRebate = !this.config().IccConfig.Configurators.handlesWithRebateHeight && sashFrameProfile
                ? Number(sashFrameProfile.rebateWidth) * 2 || 0
                : 0;

            const sashFrame = conf.drawData.sashFrame.find(o => o.sashId === sash.id);
            const sashFittingsHeight = sashFrame
                ? sashFrame.outer.rect.height - sashFrameRebate
                : 0;
            maxHandleHeight = sashFittingsHeight;
        }
        const modalInstance = this.modalService.open({
            pageComponent: HandlesOptionsPageComponent,
            resolve: {
                selectedHandle: () => {
                    if (typeof sash === 'undefined' || sash == 'default') {
                        if (place == 'outerPull' || place === 'outerLever') {
                            return this.configurationsService.conf.Current.HandleOuter;
                        } else {
                            return this.configurationsService.conf.Current.Handle;
                        }
                    } else if (sash == 'window') {
                        if (place == 'outerPull') {
                            return this.configurationsService.conf.Current.OneHandleOuterSash.window;
                        } else {
                            return this.configurationsService.conf.Current.OneHandleSash.window;
                        }
                    } else if (sash == 'door') {
                        if (place == 'outerPull') {
                            return this.configurationsService.conf.Current.OneHandleOuterSash.door;
                        } else {
                            return this.configurationsService.conf.Current.OneHandleSash.door;
                        }
                    } else {
                        if (place == 'outerPull') {
                            return sash.handleOuter;
                        } else {
                            return sash.handle;
                        }
                    }
                },
                handleHeights: () => this.getHandleHeightsForSash(sash),
                handleY: () => sash.rHandleY,
                sashHeight: () => sash.rHeight,
                showHeightSelect: () => {
                    if (
                        typeof sash === 'undefined'
                        || sash == 'default'
                        || sash == 'window'
                        || sash == 'door'
                    ) {
                        return false;
                    } else {
                        return true;
                    }
                },
                handleColors: () => this.handleColorsAll,
                selHandleColor: () => {
                    if (typeof sash === 'undefined' || sash == 'default') {
                        if (place == 'outerPull') {
                            return this.configurationsService.conf.Current.HandleOuterColor;
                        } else {
                            return this.configurationsService.conf.Current.HandleColor;
                        }
                    } else if (sash == 'window') {
                        if (place == 'outerPull') {
                            return this.configurationsService.conf.Current.OneHandleOuterSash
                                .windowColor;
                        } else {
                            return this.configurationsService.conf.Current.OneHandleSash.windowColor;
                        }
                    } else if (sash == 'door') {
                        if (place == 'outerPull') {
                            return this.configurationsService.conf.Current.OneHandleOuterSash.doorColor;
                        } else {
                            return this.configurationsService.conf.Current.OneHandleSash.doorColor;
                        }
                    } else {
                        if (place == 'outerPull') {
                            return sash.handleOuterColor;
                        } else {
                            return sash.handleColor;
                        }
                    }
                },
                selHandleOuterColor: () => {
                    if (typeof sash === 'undefined' || sash == 'default') {
                        return this.configurationsService.conf.Current.HandleOuterColor;
                    } else if (sash == 'window') {
                        return this.configurationsService.conf.Current.OneHandleOuterSash.windowColor;
                    } else if (sash == 'door') {
                        return this.configurationsService.conf.Current.OneHandleOuterSash.doorColor;
                    } else {
                        return sash.handleOuterColor;
                    }
                },
                sashType: () => {
                    if (
                        typeof sash === 'undefined'
                        || sash == 'default'
                        || sash == 'window'
                        || sash == 'door'
                    ) {
                        return null;
                    } else {
                        return sash.type.type;
                    }
                },
                position: () => {
                    if (
                        typeof sash === 'undefined'
                        || sash == 'default'
                        || sash == 'window'
                        || sash == 'door'
                    ) {
                        return null;
                    } else {
                        return sash.type.handle_position;
                    }
                },
                availColorOuter: () =>
                    (place == 'innerLever' && !this.outerHandleAvailable())
                    && (place === 'outerLever' && this.outerHandleAvailable())
                    && this.configurationsService.conf.Current.HandleType == 'DoubleLever'
                    && ((this.config().IccConfig.Configurators.door.availHandleColorOuter
                        && this.configurationsService.conf.Current.type == 'door')
                        || this.configurationsService.conf.Current.type != 'door'),
                place: () => place,
                handleHeightType: () => sash.handleHeightType,
                maxHandleHeight: () => maxHandleHeight,
                minHandleHeight: () => minHandleHeight,
                systemHeight: () => this.handleHeightService.systemHeight,
            },
        });

        modalInstance.result.then(selectedData => {
            if (Common.isObject(selectedData) && Common.isDefined(selectedData.handle)) {
                if (
                    typeof sash === 'undefined'
                    || sash == 'default'
                    || sash == 'window'
                    || sash == 'door'
                ) {
                    if (place == 'innerLever' || place == 'innerPull' || !place) {
                        this.setHandleColorById(
                            selectedData.color,
                            undefined,
                            'inner',
                            filter,
                            selectedData.defaultColor
                        );
                        if (!selectedData.colorOuter){
                            this.setHandleColorById(
                                selectedData.color,
                                undefined,
                                'outer',
                                filter,
                                selectedData.defaultColor
                            );
                        }
                    }
                    if (
                        (place == 'innerLever'
                            && this.configurationsService.conf.Current.HandleType == 'DoubleLever')
                        || place == 'outerPull' || place === 'outerLever'
                    ) {
                        if(!(this.config().IccConfig.Configurators.sliding_door.outerHandles && place === 'innerLever')) {
                            this.setHandleColorById(
                                selectedData.colorOuter,
                                undefined,
                                'outer',
                                filter,
                                selectedData.defaultColor
                            );
                        }
                    }
                } else {
                    if (place == 'innerLever' || place == 'innerPull' || !place) {
                        this.setHandleColorById(
                            selectedData.color,
                            sash,
                            'inner',
                            undefined,
                            selectedData.defaultColor
                        );
                    }
                    if (
                        (place == 'innerLever'
                            && this.configurationsService.conf.Current.HandleType == 'DoubleLever')
                        || place == 'outerPull'
                    ) {
                        this.setHandle(sash, 'outer', selectedData.handle);
                        this.setHandleColorById(
                            selectedData.color,
                            sash,
                            'outer',
                            undefined,
                            selectedData.defaultColor
                        );
                    }
                    if (Common.isDefined(selectedData.height)) {
                        this.setHandleHeight(sash, selectedData.height, selectedData.customHeight);
                    }
                    // ustawianie polozenia klamki, jezeli jest to okno uchylne
                    if (selectedData.position && sash.type.type == 'K') {
                        sash.type.handle_position = selectedData.position;
                        this.constructionLimitationService.findReinforcement(
                            this.configurationsService.conf.Current
                        );
                        this.eventBusService.post({
                            key: 'icc-redraw',
                            value: 'sash',
                        });
                    }
                }

                this.eventBusService.post({ key: 'processDependencies', value: null });
            }
            this.eventBusService.post({
                key: 'icc-redraw',
                value: 'frame',
            });
            this.priceService.count();
            this.timeLimitService.count();
        });
        modalInstance.closed.then(() => {
            if (this.config().IccConfig.Configurators.tutorialAvailable) {
                this.eventBusService.post({
                    key: 'tutorialSteps',
                    value: 'getStepImg',
                });
            }
        });
    }
    /**
     * Okno modalne z wyborem zawiasów
     */
    openModalHinge() {
        const modalInstance = this.modalService.open({
            templateUrl: 'modalHinge.html',
            controller: 'ModalHingeCtrl as mhinge',
            pageComponent: HingesPageComponent,
            resolve: {
                hinges: () => this.findHingesBySystem(this.configurationsService.conf.Current.System),
                selHinge: () => this.configurationsService.conf.Current.Hinge,
                hingeColors: () => this.handleColorsAll,
                selHingeColor: () => this.configurationsService.conf.Current.HingeColor,
                b2c: () => this.userService.b2c,
            },
        });
        if (this.config().IccConfig.Configurators.tutorialAvailable) {
            this.eventBusService.post({
                key: 'tutorialSteps',
                value: 'modalHinge',
            });
        }


        modalInstance.result.then((selectedData) => {
            if (Common.isObject(selectedData) && Common.isDefined(selectedData.hinge)) {
                this.setHinge(selectedData.hinge);
                this.setHingeColorById(selectedData.color, selectedData.defaultColor);
                this.eventBusService.post({ key: 'processDependencies', value: null });
                this.priceService.count();
                this.eventBusService.post({
                    key: 'icc-redraw',
                    value: null,
                });
            }
        });

        modalInstance.closed.then(() => {
            if (this.config().IccConfig.Configurators.tutorialAvailable) {
                this.eventBusService.post({
                    key: 'tutorialSteps',
                    value: 'getStepImg',
                });
            }
        });
    }

        /**
     * Okno modalne z wyborem klamki
     * @param  {object} sash      Skrzydło
     * @param  {string} place     Położenie
     * @param  {string} type      Typ
     */
    openModalHingeOptions(sash, place, type) {
        const doorTypes = ['DRA', 'DOA'];
        let filter = (sash: ActiveSash) => true;
        if (sash == 'window') {
            filter = s => doorTypes.indexOf(s.type.type) == -1;
        } else if (sash == 'door') {
            filter = s => doorTypes.indexOf(s.type.type) > -1;
        }
        const modalInstance = this.modalService.open({
            pageComponent: HingesOptionsPageComponent,
            resolve: {
                selectedHinge: () =>  this.configurationsService.conf.Current.Hinge,
                hingeColors: () => this.handleColorsAll,
                selHingeColor: () => this.configurationsService.conf.Current.HingeColor
            },
        });

        modalInstance.result.then(selectedData => {
            if (Common.isObject(selectedData) && Common.isDefined(selectedData.hinge)) {
                this.setHingeColorById(selectedData.color);
            }
            this.priceService.count();
            this.timeLimitService.count();
            this.eventBusService.post({
                key: 'icc-redraw',
                value: null,
            });
        });
        modalInstance.closed.then(() => {
            if (this.config().IccConfig.Configurators.tutorialAvailable) {
                this.eventBusService.post({
                    key: 'tutorialSteps',
                    value: 'getStepImg',
                });
            }
        });
    }

    /**
     * Znajdowanie zawiasów dostępnych w systemie
     * @param   {any}   system System
     * @returns {array} Tablica z zawiasami pasujcymi do systemu
     */
    findHingesBySystem(
        system = undefined,
        conf = this.configurationsService.conf.Current,
    ) {
        let systemPassed = true;
        if (Common.isUndefined(conf) && !(system)) {
            this.hinges = [];
        } else {
            const sashes = conf.Sashes || [];
            const fittingId = conf.Fitting.id;

            if (!(system)) {
                system = conf.System;
                systemPassed = false;
            }

            if (
                sashes.some(sash => ['F', 'FF', 'OFF'].indexOf(sash.type.type) === -1)
                && Common.isArray(this.allHinges)
            ) {
                this.hinges = this.allHinges
                    .filter(
                        hinge =>
                            Common.isArray(hinge.window_lines_ids)
                            && Common.isArray(hinge.window_fittings_ids)
                            && hinge.window_lines_ids.indexOf(system.id) > -1
                            && hinge.window_fittings_ids.indexOf(fittingId) > -1
                            && hinge.type == 'hinge_' + system.type
                    )
                    .map(hinge => {
                        if (Common.isArray(hinge.colors_ids)) {
                            hinge.colors_ids = hinge.colors_ids.sort((a, b) => a - b);
                        }
                        return hinge;
                    });
            } else {
                this.hinges = [];
            }
        }

        if (this.hinges.length) {
            this.isMatch.ref = true;
        } else {
            this.isMatch.ref = false;
            conf.Hinge = null;
            conf.HingeColor = null;
        }

        return this.hinges;
    }

    isHingeBasedOnDependenciesAvailable(hingeId = this.configurationsService.conf.Current.Hinge.id, conf = this.configurationsService.conf.Current) {
        let isHingeAvailable = true;

        const blockedItems = this.configurationsService.conf.Current.Dependencies.filter(
            (p) => p.type === 'blockade_to_configuration'
        );

        if (Array.isArray(blockedItems) && !blockedItems.length || !hingeId) {
            return isHingeAvailable;
        }

        this.dependencies.forEach(dependency => {
            blockedItems.forEach(blocked => {
                if (dependency && blocked && dependency.id === Number(String(blocked.id).split('-')[0])) {
                    dependency.conditions.forEach(condition => {
                        if (condition.type === 'accessory') {
                            if (hingeId && condition.value && Number(hingeId) === Number(condition.value)) {
                                if ("blockedSashLayoutVariant" in dependency) {
                                    isHingeAvailable = !this.checkSashLayoutVariant(dependency.conditions?.find(k => k.type === 'sashLayoutVariant')?.value, this.configurationsService.conf.Current)
                                } else if ("blockedExtension" in dependency && dependency.blockedExtension && conf.SideProfiles.length) {
                                    isHingeAvailable = !this.checkExtension(dependency.conditions?.find(k => k.type === 'extension')?.value, this.configurationsService.conf.Current)
                                }
                            }
                        }
                    })
                }
            });
        });

        return isHingeAvailable;
    }

    private removeExtensionsBasedOnBlockadeToConfiguration(
        conf: WindowActiveConfiguration | DoorActiveConfiguration
    ) {
        const blockedItems = this.configurationsService.conf.Current.Dependencies.filter(
            (p) => p.type === 'blockade_to_configuration'
        );

        if (!blockedItems.length || !conf.SideProfiles.length) {
            return;
        }

        this.dependencies.forEach(dependency => {
            blockedItems.forEach(blocked => {
                if (dependency && blocked && dependency.id === Number(String(blocked.id).split('-')[0])) {
                    dependency.conditions.forEach(condition => {
                        if (condition.type === 'accessory' && conf.Hinge?.id && condition.value && Number(conf.Hinge.id) === Number(condition.value)) {
                            if ("blockedExtension" in dependency && dependency.blockedExtension) {
                                const isHingeAvailable = !this.checkExtension(dependency.conditions?.find(k => k.type === 'extension')?.value, this.configurationsService.conf.Current)
                                if (!isHingeAvailable) {
                                    const extension = dependency.conditions.find((d) => d.type === 'extension');
                                    if (!extension) return;

                                    const extensionIds = extension?.value.extensionTypes.map((k) => k.id);
                                    if (!extensionIds.length) return;

                                    const sides = extensionIds.includes('ALL_EXTENSIONS')
                                        ? conf.SideProfiles.map((sp) => sp.side)
                                        : conf.SideProfiles.filter((p) => extensionIds.includes(p.profileId))?.map(
                                            (sp) => sp.side
                                        );

                                    if (!sides.length) return;

                                    const dependencies = dependency.conditions.filter(k => k.type === "extension")
                                    if (dependencies.some(d => this.checkExtension(d.value, conf))) {
                                        this.eventBusService.post({
                                            key: 'removeExtensionBySide',
                                            value: sides,
                                        });
                                    }
                                }
                            }
                        }
                    })
                }
            });
        });
    }

    checkExtension(value: { extensionOptions, extensionTypes }, conf: WindowActiveConfiguration) {
        const ALL_EXTENSIONS = "ALL_EXTENSIONS";
        const extensionIds = value.extensionTypes.map(k => k.id);

        if (!extensionIds.length) {
            return;
        }

        if (value?.extensionOptions?.includes('from_hinge_side')) {
            const handlePosition = conf.Sashes.find((k) => k.type.handle_position).type.handle_position;
            switch(handlePosition) {
                case 'L': {
                    const sideProfiles = conf.SideProfiles.filter(k => k.side === 'right');
                    return (
                        (extensionIds.includes(ALL_EXTENSIONS) && sideProfiles.length) ||
                        sideProfiles.some((sp) => extensionIds.includes(sp.profileId))
                    );
                }
                case 'R': {
                    const sideProfiles = conf.SideProfiles.filter(k => k.side === 'left');
                    return (
                        (extensionIds.includes(ALL_EXTENSIONS) && sideProfiles.length) ||
                        sideProfiles.some((sp) => extensionIds.includes(sp.profileId))
                    );
                }
                default: return false
            }
        } else {
            return (
                value.extensionTypes.some((k) => conf.SideProfiles.some(sp => sp.profileId === k.id)) ||
                extensionIds.includes(ALL_EXTENSIONS) && conf.SideProfiles.length
            );
        }
    }

    /**
     * Funkcja walidujaca
     * @param  {object} conf Konfiguracja
     */
    valid(conf) {
        if (this.configurators.indexOf(this.currentConfiguratorService.conf) === -1) {
            this.issuesService.unregister('incorrect-handle', conf);
            return true;
        }
        const reasons = [];
        let blockAddToOffer = false;
        for (let i = 0; i < conf.Sashes.length; i++) {
            const sash = conf.Sashes[i];
            if (
                this.currentConfiguratorService.conf != 'door'
                && Common.isDefined(sash.handle.window_fittings_ids)
                && Common.isObject(conf.Fitting)
                && sash.handle.window_fittings_ids.indexOf(conf.Fitting.id) == -1
                && this.noHandleTypes.indexOf(sash.type.type) == -1
            ) {
                reasons.push({
                    sashId: i + 1,
                    sashIndex: sash.index,
                    reason: this.translateService.instant(
                        'WINDOW|Wybrana klamka nie pasuje do okucia bezpiecznego.'
                    ),
                    handle: sash.handle.name,
                    fitting: conf.Fitting.name,
                });
                blockAddToOffer =
                    blockAddToOffer || !this.config().IccConfig.Configurators.selectHandleOnChangeFitting;
            }

            if (Common.isUndefined(sash.handle) && this.noHandleTypes.indexOf(sash.type.type) == -1) {
                reasons.push({
                    sashId: i + 1,
                    sashIndex: sash.index,
                    reason: this.translateService.instant(
                        'WINDOW|Żadna klamka nie pasuje do wybranego systemu i typu skrzydła.'
                    ),
                    handle: 'INTERFACE|Brak',
                    fitting: conf.Fitting.name,
                });
                blockAddToOffer = true;
            }
            if(
                this.config().IccConfig.Configurators.sizeRangesFitting
                && this.currentConfiguratorService.conf !== 'door'
                && Common.isDefined(sash.handle.windowFittingsWithRelationType)
                && Common.isObject(conf.Fitting)
            ) {
                const fitting = conf.Fitting;
                const fittingRelation = sash?.handle?.windowFittingsWithRelationType.find((relation: WindowFittingRelation) =>
                    Number(relation.windowFittingId) === Number(fitting.id)
                ) || false;
                if (fittingRelation.relationType && fittingRelation.relationType !== 'available') {
                    reasons.push({
                        sashId: i + 1,
                        sashIndex: sash.index,
                        reason: fittingRelation.relationType === 'communique'
                            ? this.translateService.instant('WINDOW|Wybrana klamka nie jest zalecana do wybranego okucia.')
                            : this.translateService.instant('WINDOW|Blokada dodania do oferty.'),
                        handle: sash.handle.name,
                        fitting: conf.Fitting.name,
                    });
                    blockAddToOffer = blockAddToOffer || ['blockade', 'blockadeToOffer'].indexOf(fittingRelation.relationType) !== -1;
                }
            }
            if(
                this.config().IccConfig.Configurators.sizeRangesFitting
                && this.currentConfiguratorService.conf !== 'door'
                && Common.isDefined(sash.handleOuter.windowFittingsWithRelationType)
                && Common.isObject(conf.Fitting)
            ) {
                const fitting = conf.Fitting;
                const fittingRelationOuter = sash?.handleOuter?.windowFittingsWithRelationType.find((relation: WindowFittingRelation) =>
                    Number(relation.windowFittingId) === Number(fitting.id)
                ) || false;
                if (fittingRelationOuter.relationType && fittingRelationOuter.relationType !== 'available') {
                    reasons.push({
                        sashId: i + 1,
                        sashIndex: sash.index,
                        reason: fittingRelationOuter.relationType === 'communique'
                            ? this.translateService.instant('WINDOW|Wybrana klamka nie jest zalecana do wybranego okucia.')
                            : this.translateService.instant('WINDOW|Blokada dodania do oferty.'),
                        handleOuter: sash.handleOuter.name,
                        fitting: conf.Fitting.name,
                    });
                    blockAddToOffer = blockAddToOffer || ['blockade', 'blockadeToOffer'].indexOf(fittingRelationOuter.relationType) !== -1;
                }
            }
        }

        if (reasons.length) {
            conf.Issues = conf.Issues.filter(i => i.key !== 'incorrect-handle');
            const reason =
                reasons.reduce(
                    (prev, e) =>
                        prev
                        + `<li>${e.reason}<b>
                ${this.translateService.instant('WINDOW|Okucie')}: ${e.fitting},
                ${e.handle ? this.translateService.instant('WINDOW|Klamka') : this.translateService.instant('WINDOW|Klamka zewnętrzna') }: ${e.handle ? e.handle : e.handleOuter},
                ${this.translateService.instant('WINDOW|Kwatera')}: ${e.sashIndex}
            </b></li>`,
                    '<ul>'
                ) + '</ul>';
            this.issuesService.simpleRegister('incorrect-handle', 'Nie pasujące klamki', reason, conf, {
                blockAddToOffer,
                logLevel: IssueLevel.NONE,
                extra: {
                    reason
                }
            });
            return false;
        } else {
            this.issuesService.unregister('incorrect-handle', conf);
            return true;
        }
    }

    getAccessoriesIdsBasedOnBlockadeToConfiguration(blockedAccessories, dependencies) {
        if (!(Array.isArray(blockedAccessories) && blockedAccessories.length && dependencies)) {
            return [];
        }

        const accessoryIds = [];
        dependencies.forEach((dependency) => {
            blockedAccessories.forEach((blocked) => {
                if (dependency && Number(dependency.id) === Number(String(blocked.id).split('-')[0]) && dependency.conditions) {
                    dependency.conditions.forEach((condition) => {
                        if (condition.type === 'accessory') {
                            if ("blockedSashLayoutVariant" in dependency) {
                                if (dependency.blockedSashLayoutVariant) {
                                    const checkSashLayoutVariant = this.checkSashLayoutVariant(dependency.conditions?.find(k => k.type === 'sashLayoutVariant')?.value, this.configurationsService.conf.Current)
                                    if (checkSashLayoutVariant) {
                                        accessoryIds.push(condition.value);
                                    }
                                }
                            } else if ("blockedExtension" in dependency) {
                                if (dependency.blockedExtension) {
                                    const checkExtension = this.checkExtension(dependency.conditions?.find(k => k.type === 'extension')?.value, this.configurationsService.conf.Current)
                                    if (checkExtension) {
                                        accessoryIds.push(condition.value);
                                    }
                                }
                            } else {
                                accessoryIds.push(condition.value);
                            }
                        }
                    });
                }
            });
        });

        return accessoryIds;
    }

    checkSashLayoutVariant(dependencyValue: { sashesLayoutsVariantsOptions, sashesLayoutsVariantsTypes}, conf: WindowActiveConfiguration) {
        const ALL_SASHES_LAYOUTS_VARIANTS = "ALL_SASHES_LAYOUTS_VARIANTS";
        const layoutsIds = dependencyValue?.sashesLayoutsVariantsTypes?.map(k => k.id);

        if (!layoutsIds.length) {
            return false;
        }

        const selectedAllSashesVariants = layoutsIds.includes(ALL_SASHES_LAYOUTS_VARIANTS);
        const isCurrentLayoutBlocked = dependencyValue.sashesLayoutsVariantsTypes.some((k) => k.id === conf.Layout.id);

        if (dependencyValue?.sashesLayoutsVariantsOptions?.includes('from_hinge_side')) {
            const handlePosition = conf.Sashes?.find((k) => k.type?.handle_position)?.type?.handle_position;

            switch(handlePosition) {
                case 'L': {
                    return selectedAllSashesVariants && conf.OwnedSashesTypes.doorRightLight ||
                    isCurrentLayoutBlocked
                }
                case 'R': {
                    return selectedAllSashesVariants && conf.OwnedSashesTypes.doorLeftLight ||
                    isCurrentLayoutBlocked
                }
                default: return false;
            }
        } else {
            return (
                isCurrentLayoutBlocked ||
                (selectedAllSashesVariants ? (
                    conf.OwnedSashesTypes.doorRightLight ||
                    conf.OwnedSashesTypes.doorLeftLight ||
                    conf.OwnedSashesTypes.doorTopLight) : false)
            )
        }
    }

    hasOuterHandles(type: 'DoubleLever' | 'LeverPull') {
      return  this.handles.filter((el) => {
            if(type === 'DoubleLever' && !this.handleHasOuterHandleType(el, 'DoubleLever')) {
                return false;
            }
            if(type === 'LeverPull' && !this.handleHasOuterHandleType(el, 'LeverPull')) {
                return false;
            }
            return true;
        }).length > 0;
    }

    handleHasOuterHandleType(handle, type: 'DoubleLever' | 'LeverPull' ) {
        if(!handle.outer_handles_id.length) {
            return false;
        }
        let outerHandles = this.handles.filter(h => handle.outer_handles_id.find(outerHandleId => Number(outerHandleId) === Number(h.id)));
        const sashTypes = this.getSashTypes();
        outerHandles = outerHandles.filter(el => {
            const availableSashTypesIds = el.sash_types_ids;
            for (let j = 0; j < sashTypes.length; j++) {
                if (Common.isUndefined(sashTypes[j])) {
                    continue;
                }
                if (availableSashTypesIds.indexOf(sashTypes[j]) === -1) {
                    return false;
                }
                return true;
            }
        });
        if(!outerHandles.length) {
            return false;
        }

        if(type === 'LeverPull' && !outerHandles.some(outerHandle => outerHandle.handle_type === 'pull')) {
            return false;
        }

        if(type === 'DoubleLever' && !outerHandles.some(outerHandle => outerHandle.handle_type !== 'pull')) {
            return false;
        }

        return true;
    }

    validateHandleAndFixIssues(conf = this.configurationsService.conf.Current) {
        conf.Sashes.forEach(sash => {
                this.validHandleInSashAndFixIssues(conf, sash, 'handle');
                if(conf.HandleType !== 'InnerLever') {
                    this.validHandleInSashAndFixIssues(conf, sash, 'handleOuter');
                } else {
                    sash.handleOuter = {};
                }
        });

       this.checkIsOneHandleAndAllHasHandle(conf);
    }

    validHandleInSashAndFixIssues(conf = this.configurationsService.conf.Current, sash: ActiveSash, handlePosition: 'handle' | 'handleOuter') {
        if(this.noHandleTypes.indexOf(sash.type.type) > -1) {
            return ;
        }
        if(!sash?.[handlePosition]?.id) {
            this.setDefaultHandleForSash(conf, sash, handlePosition);
            return ;
        }
        const handleType = conf.HandleType ?? 'InnerLever'; 
        const handlePlace = handlePosition === 'handle' ? 'inner' : 'outer';
        const handles = this.getHandlesForSash(sash, handlePlace, handleType);
        const currentHandle = handles.find(handle => Number(handle.id) === Number(sash[handlePosition].id));
        
        if(!currentHandle || sash[handlePosition].isDefault) {
            this.setDefaultHandleForSash(conf, sash, handlePosition);
        }

    }

    setDefaultHandleForSash(conf = this.configurationsService.conf.Current, sash: ActiveSash, handlePosition: 'handle' | 'handleOuter') {
        const defaultHandle = this.getDefaultHandleIdForSash(conf, sash, handlePosition);
        sash[handlePosition] = defaultHandle;
    }

    getDefaultHandleIdForSash(conf = this.configurationsService.conf.Current, sash: ActiveSash, handlePosition: 'handle' | 'handleOuter') {
        this.issuesService.unregister(
            'no-handle-available',
            conf
        );
        const defaultHandleIdFromSystem = handlePosition === 'handle' 
            ? conf.System.default_market_configuration?.window_handler_id 
                ? conf.System.default_market_configuration?.window_handler_id 
                : conf.System.default_window_handler_id
            : null;
        
        const handleType = conf.HandleType ?? 'InnerLever'; 
        const handlePlace = handlePosition === 'handle' ? 'inner' : 'outer';
        const handles = this.getHandlesForSash(sash, handlePlace, handleType);
        const currentHandle  = handlePosition === 'handle' ? conf.Handle : conf.HandleOuter;
        if(!sash[handlePosition]?.id && currentHandle && currentHandle.id && handles.find(handle => Number(handle.id) === Number(currentHandle.id))) {
            return currentHandle
        }
        if(defaultHandleIdFromSystem) {
            const defaultHandle = handles.find(handle => Number(handle.id) === Number(defaultHandleIdFromSystem));
            if(defaultHandle) {
                return {...defaultHandle, isDefault: true};
            }
        }
        if(handles.length) {
            return {...handles[0], isDefault: true};
        }

        this.issuesService.registerDataProblem(
            'no-handle-available',
            'Żadna klamka nie pasuje do wybranego systemu i skrzydła',
            conf,
            {
                extra: {
                    systemId: conf.System.id,
                    systemName: conf.System.name,
                    sashType: sash.type.name,
                    sashTypeType: sash.type.type,
                },
            }
        );
        return {};
    }

    getHandlesForSash(sash: ActiveSash, handlePlace: HandlesPlace, handleType: HandlesType) {
        const sashTypeId = Number(sash.type?.id);
        return this.handles.filter(this.filterHandlesByPlaceAndType(handlePlace, handleType)).filter(handle => handle.sash_types_ids.map(Number).indexOf(sashTypeId) > -1);
    }

}
